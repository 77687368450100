import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Ast from '../components/ast'
import Seo from '../components/seo'
import Carousel from '../components/featured-carousel'
import Heading from '../components/page-heading'

export default ({ data: { allContentfulServicePage: { edges } } }) => {
  const { node } = edges[0]
  const { title, body } = node
  const htmlAst = body.childMarkdownRemark.htmlAst

  return (
    <Layout customSeo={`true`}>
      <Seo servicePage={node} />
      <Heading>{title}</Heading>
      <Ast ast={htmlAst} />
      <Carousel list={node.serviceList} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServicePage {
    allContentfulServicePage {
      edges {
        node {
          path {
            url
            label
          }
          title
          body {
            childMarkdownRemark {
              excerpt
              html
              htmlAst
            }
          }
          serviceList {
            slug
            service {
              path {
                url
              }
              title
              description {
                childMarkdownRemark {
                  html
                  htmlAst
                }
              }
            }
          }
        }
      }
    }
  }
`
